import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { initAuthorizationToken } from "./redux/actions/action-auth";

import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { store } from "./redux/Store";

import { createBrowserHistory } from "history";
import initAPIConfig from "./config/config-api";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";

unregisterServiceWorker();

const history = createBrowserHistory();

initAPIConfig(store.dispatch);
initAuthorizationToken(store.dispatch);

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  rootElement
);
