import {
  REQUEST_PROVIDER_OPTION,
  RECEIVE_PROVIDER_OPTION,
} from '../constant-type/constant-type-provider';

const InitialState = {
  loading: false,
  code: null,
  payload: [],
};

const provider = (state = InitialState, { type, payload, code }) => {
  switch (type) {
    case REQUEST_PROVIDER_OPTION:
      return Object.assign({}, state, { loading: true });
    case RECEIVE_PROVIDER_OPTION:
      return Object.assign({}, state, { loading: false, payload, code });

    default:
      return state;
  }
};

export default provider;
