import axios from 'axios';
import * as jwt_decode from 'jwt-decode';
import jwt from 'jsonwebtoken';
import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
  AUTH_LOGOUT,
} from '../constant-type/constant-type-auth';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

const checkRole = (token, role) => {
  return token.role.filter(value => role.includes(value));
};

export function logIn(fields) {
  return async dispatch => {
    dispatch({ type: AUTH_LOGIN_REQUEST });
    dispatch(showLoading('sectionBar'));
    try {
      var res = await axios.post(`/auth`, {}, { auth: fields });
      const token = res.headers.authorization.split(' ');
      const data = jwt_decode(token[1]);
      const role = ['admin', 'naa', 'ngm', 'superuser'];

      // Validasi apakah mempunyai role admin
      if (checkRole(data, role).length === 0) {
        const error = {
          response: {
            code: 400,
            data: {
              message: 'Maaf Anda tidak memiliki akses kedalam sistem ini',
            },
          },
        };
        throw error;
      }
      setAuthorizationToken(token[1]);
      dispatch({ type: AUTH_LOGIN_SUCCESS, payload: data });
      dispatch(hideLoading('sectionBar'));
      return res.data;
    } catch (error) {
      dispatch(hideLoading('sectionBar'));
      dispatch({ type: AUTH_LOGIN_FAILURE });
      if (error.response) {
        return error.response.data;
      } else {
        let data = {
          code: 402,
          message:
            'Maaf terjadi kesalahan pada Permintaan Anda, silakan coba beberapa saat lagi!',
        };

        return data;
      }
    }
  };
}

export function initAuthorizationToken(dispatch) {
  const token = localStorage.getItem('TOKEN_ADMIN') || null;
  if (token !== null) {
    var decoded = jwt.decode(token, { complete: true });
    setAuthorizationToken(token);
    dispatch({ type: AUTH_LOGIN_SUCCESS, payload: decoded.payload });
  } else {
    unsetAuthorizationToken();
  }
}

export function setAuthorizationToken(token) {
  const storedToken = localStorage.getItem('TOKEN_ADMIN') || null;
  if (storedToken === null || (storedToken !== null && storedToken !== token)) {
    localStorage.setItem('TOKEN_ADMIN', token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
}

export function unsetAuthorizationToken() {
  delete axios.defaults.headers.common.Authorization;
  localStorage.removeItem('TOKEN_ADMIN');
}

export function logout() {
  return dispatch => {
    unsetAuthorizationToken();
    dispatch({ type: AUTH_LOGOUT });
  };
}
