import React, { Component, Suspense, lazy } from 'react';

import { Route, Switch, HashRouter, withRouter, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import '@coreui/icons/css/coreui-icons.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import 'rc-pagination/assets/index.css';
import './assets/scss/style.css';
import './assets/scss/style-change.css';
import './assets/scss/style-skeleton-screen.css';
import 'react-times/css/classic/default.css';
import 'react-responsive-select/dist/ReactResponsiveSelect.css';
import 'antd/dist/antd.css';
import './assets/scss/style-form.css';
import './assets/scss/style-table.css';
import './assets/scss/style-flex.css';
import './assets/scss/style-input.css';
import './assets/scss/style-antd.css';
import './assets/scss/style-card.css';
import './assets/scss/global.css';
import 'moment/locale/id';

import numeral from 'numeral';
import 'numeral/locales';
import Loading from './components/Loading/Loading';
import NotifSystem from './components/notif-system';
numeral.locale('pt-br');
moment.locale('id');

const DefaultLayout = withRouter(lazy(() => import('./containers/DefaultLayout')));
const Login = withRouter(lazy(() => import('./views/Login/LoginContainer')));
// const pageNotFound = withRouter(lazy(<Page404 />));

function PrivateRoute({ component: Component, props }) {
  const data = useSelector(state => state.auth);
  return (
    <Route
      {...props}
      render={props =>
        data.loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute() {
  const data = useSelector(state => state.auth);
  return !data.loggedIn ? <Redirect to="/login" /> : <Redirect to="/beranda" />;
}

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <NotifSystem />
        <Suspense fallback={<Loading icon />} timeout={5000}>
          <HashRouter>
            <Switch>
              <Route exact path="/" component={PublicRoute} />
              <Route path="/login" component={Login} />
              <PrivateRoute path="/" component={DefaultLayout} />
              {/* <Route path="*" component={<div>Hallo</div>} /> */}
            </Switch>
          </HashRouter>
        </Suspense>
      </React.Fragment>
    );
  }
}

export default App;
