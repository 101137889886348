export const GET_ADVERTISEMENT = 'GET_ADVERTISEMENT';
export const POST_ADVERTISEMENT = 'POST_ADVERTISEMENT';
export const GET_ADVERTISEMENT_ID = 'GET_ADVERTISEMENT_ID';
export const PUT_ADVERTISEMENT = 'PUT_ADVERTISEMENT';
export const DELETE_ADVERTISEMENT = 'DELETE_ADVERTISEMENT';

export const REQUEST = {
  GET_ADVERTISEMENT: `REQUEST_${GET_ADVERTISEMENT}`,
  PUT_ADVERTISEMENT: `REQUEST_${PUT_ADVERTISEMENT}`,
  POST_ADVERTISEMENT: `REQUEST_${POST_ADVERTISEMENT}`,
  GET_ADVERTISEMENT_ID: `REQUEST_${GET_ADVERTISEMENT_ID}`,
  DELETE_ADVERTISEMENT: `REQUEST_${DELETE_ADVERTISEMENT}`
};

export const RECEIVE = {
  GET_ADVERTISEMENT: `RECEIVE_${GET_ADVERTISEMENT}`,
  PUT_ADVERTISEMENT: `RECEIVE_${PUT_ADVERTISEMENT}`,
  POST_ADVERTISEMENT: `RECEIVE_${POST_ADVERTISEMENT}`,
  GET_ADVERTISEMENT_ID: `RECEIVE_${GET_ADVERTISEMENT_ID}`,
  DELETE_ADVERTISEMENT: `RECEIVE_${DELETE_ADVERTISEMENT}`
};

export const FAILURE = {
  GET_ADVERTISEMENT: `FAILURE_${GET_ADVERTISEMENT}`,
  PUT_ADVERTISEMENT: `FAILURE_${PUT_ADVERTISEMENT}`,
  POST_ADVERTISEMENT: `FAILURE_${POST_ADVERTISEMENT}`,
  GET_ADVERTISEMENT_ID: `FAILURE_${GET_ADVERTISEMENT_ID}`,
  DELETE_ADVERTISEMENT: `FAILURE_${DELETE_ADVERTISEMENT}`
};
