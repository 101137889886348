import {
  REQUEST,
  RECEIVE,
  FAILURE
} from "../../constant-type/constant-type-voucher";

const initialState = {
  loading: true,
  code: null,
  payload: [],
  perpage: 0,
  count: 0,
  message: ""
};

const voucher = (
  state = initialState,
  { type, code, payload, perpage, count, message }
) => {
  switch (type) {
    case REQUEST.GET_VOUCHER:
      return { ...state, loading: true };

    case RECEIVE.GET_VOUCHER:
      return {
        ...state,
        loading: false,
        code,
        perpage,
        count,
        payload,
        message
      };

    case FAILURE.GET_VOUCHER:
      return {
        ...state,
        loading: false,
        code,
        perpage,
        count,
        payload,
        message
      };

    default:
      return state;
  }
};

export default voucher;
