import React, { useRef, useEffect } from 'react';
import NotificationSystem from 'react-notification-system';
import { useSelector, useDispatch } from 'react-redux';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

export default function NotifSystem(props) {
  const notificationSystem = useRef();
  const store = useSelector(state => state.notificationSystem);
  const dispatch = useDispatch();

  useEffect(
    () => {
      const notification = notificationSystem.current;
      if (store.show) {
        notification.addNotification({
          level: 'success',
          autoDismiss: 2,
          dismissible: 'none',
          position: store.position,
          children: (
            <Toast>
              <ToastHeader icon={<i className="fas fa-check-square text-success" />}>
                Pesan
              </ToastHeader>
              <ToastBody>{store.message}</ToastBody>
            </Toast>
          ),
        });
      }
    },
    [store.show, notificationSystem, dispatch]
  );

  var style = {
    NotificationItem: {
      DefaultStyle: {
        margin: '0',
        backgroundColor: 'none !important',
        border: 'none',
        boxShadow: 'none',
      },
    },
  };

  return <NotificationSystem ref={notificationSystem} style={style} />;
}
