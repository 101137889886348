// import { RECEIVE, REQUEST } from '../models/constant-type-req-rec';
import {
  RECEIVE_MENUS,
  REQUEST_MENUS
} from "../constant-type/constant-type-menus";

const InitialState = {
  code: null,
  loading: false,
  payload: []
};

const menu = (state = InitialState, { type, payload, code }) => {
  switch (type) {
    case REQUEST_MENUS:
      return Object.assign({}, state, { loading: true });

    case RECEIVE_MENUS:
      return Object.assign({}, state, { loading: false, payload, code });

    default:
      return state;
  }
};

export default menu;
