import {
  RECEIVE,
  REQUEST,
  FAILURE
} from '../../constant-type/constant-type-denda';

const InitialState = {
  code: null,
  loading: false,
  payload: [],
  error: {},
  perpage: 0,
  count: 0
};

const dendaGet = (
  state = InitialState,
  { type, payload, code, perpage, count }
) => {
  switch (type) {
    case REQUEST.GET_DENDA:
      return { ...state, loading: true };

    case RECEIVE.GET_DENDA:
      return { ...state, loading: false, payload, code, perpage, count };

    case FAILURE.GET_DENDA:
      return {
        ...state,
        loading: false,
        error: payload
      };

    default:
      return state;
  }
};

export default dendaGet;
