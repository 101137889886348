export const GET_DENDA = 'GET_DENDA';
export const GET_DENDA_ID = 'GET_DENDA_ID';
export const PUT_DENDA = 'PUT_DENDA';
export const POST_DENDA = 'POST_DENDA';
export const DELETE_DENDA = 'DELETE_DENDA';

export const REQUEST = {
    GET_DENDA: `REQUEST_${GET_DENDA}`,
    PUT_DENDA: `REQUEST_${PUT_DENDA}`,
    POST_DENDA: `REQUEST_${POST_DENDA}`,
    GET_DENDA_ID: `REQUEST_${GET_DENDA_ID}`,
    DELETE_DENDA: `REQUEST_${DELETE_DENDA}`
};

export const RECEIVE = {
    GET_DENDA: `RECEIVE_${GET_DENDA}`,
    PUT_DENDA: `RECEIVE_${PUT_DENDA}`,
    POST_DENDA: `RECEIVE_${POST_DENDA}`,
    GET_DENDA_ID: `RECEIVE_${GET_DENDA_ID}`,
    DELETE_DENDA: `RECEIVE_${DELETE_DENDA}`
};

export const FAILURE = {
    GET_DENDA: `FAILURE_${GET_DENDA}`,
    PUT_DENDA: `FAILURE_${PUT_DENDA}`,
    POST_DENDA: `FAILURE_${POST_DENDA}`,
    GET_DENDA_ID: `FAILURE_${GET_DENDA_ID}`,
    DELETE_DENDA: `FAILURE_${DELETE_DENDA}`
};

export const DEFAULT = {
    GET_DENDA: `DEFAULT_${GET_DENDA}`,
    PUT_DENDA: `DEFAULT_${PUT_DENDA}`,
    POST_DENDA: `DEFAULT_${POST_DENDA}`,
    GET_DENDA_ID: `DEFAULT_${GET_DENDA_ID}`,
    DELETE_DENDA: `DEFAULT_${DELETE_DENDA}`
};
