import { SHOW_NOTIFICATION_SYSTEM } from '../../actions/action-notification-system/constant-type';

const initialState = {
  show: 0,
  message: '',
  position: 'tc',
};

export default function notification_system(state = initialState, action) {
  switch (action.type) {
    case SHOW_NOTIFICATION_SYSTEM:
      return {
        ...state,
        show: state.show + 1,
        message: action.message,
        position: action.position || 'tc',
      };
    default:
      return state;
  }
}
