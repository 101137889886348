export const REQUEST ={
    USER:'REQUEST_USER'
}
export const RECEIVE = {
   USER: 'RECEIVE_USER'
};

export const POST = {
   SERVICES: 'POST_SERVICES'
};

export const DELETE = {
   SERVICES: 'DELETE_SERVICES',
   USER_MANAGEMENT:'DELETE_USER_MANAGEMENT'
};

export const PUT = {
   PROFILE: 'PUT_PROFILE',
   SERVICES: 'PUT_SERVICES',
   OPERATION_TIMES: 'PUT_OPERATION_TIMES',
   PASSWORD: 'PUT_PASSWORD'
};