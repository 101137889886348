import {
  RECEIVE,
  REQUEST,
  FAILURE,
  GET_APARTMENT_ID
} from '../../constant-type/constant-type-apartment';

const InitialState = {
  code: null,
  loading: false,
  payload: {
    location: {
      coordinates: ['', '']
    }
  },
  error: {},
  page: 1,
  number: 1
};

const menu = (
  state = InitialState,
  { type, payload, code, perpage, count }
) => {
  switch (type) {
    case REQUEST.GET_APARTMENT_ID:
      return { ...state, loading: true };

    case RECEIVE.GET_APARTMENT_ID:
      return { ...state, loading: false, payload, code, perpage, count };

    case FAILURE.GET_APARTMENT_ID:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case `DEFAULT_${GET_APARTMENT_ID}`:
      return {
        ...state,
        loading: false,
        error: {},
        code: null
      };

    default:
      return state;
  }
};

export default menu;
