import middleware from './Middleware';
import { createStore, combineReducers, applyMiddleware } from 'redux';

// import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';
export const store = createStore(
  combineReducers(reducers), // Don't forget to register the reducers here
  // composeWithDevTools(
  applyMiddleware(...middleware)
);
