const initialState = {
  typeAlert:'',
  message:''
}
  
const notifications = (state = initialState, action) => {
    switch (action.type) {
      case 'SHOW_NOTIFICATION':
        return Object.assign({}, state, {message:action.message, typeAlert:action.typeAlert})
      case 'HIDE_NOTIFICATION':
        return Object.assign({}, state, {error:'', typeAlert:''})
      default:
        return state
    }
}

export default notifications;