import {REQUEST, RECEIVE} from '../models/constant-type-req-rec';

const InitialState = {
   loading:false,
   code:null,
   payload:{}
};

const user = (state = InitialState,{ type, code, payload }) => {
  switch (type) {
    case REQUEST.ORDERS_STATISTIC:
      return Object.assign({}, state, {loading:true})
    case RECEIVE.ORDERS_STATISTIC:
      return Object.assign({}, state, { loading: false, code, payload });
    default:
      return state;
  }
};

export default user;
