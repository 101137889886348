export const REQUEST = {
  GET_VOUCHER: `REQUEST_GET_VOUCHER`,
  GET_VOUCHER_USAGE: `REQUEST_GET_VOUCHER_USAGE`
};

export const RECEIVE = {
  GET_VOUCHER: `RECEIVE_GET_VOUCHER`,
  GET_VOUCHER_USAGE: `RECEIVE_GET_VOUCHER_USAGE`
};

export const FAILURE = {
  GET_VOUCHER: `FAILURE_GET_VOUCHER`,
  GET_VOUCHER_USAGE: `FAILURE_GET_VOUCHER_USAGE`
};
