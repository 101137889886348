export const GET_APARTMENT = 'GET_APARTMENT';
export const POST_APARTMENT = 'POST_APARTMENT';
export const GET_APARTMENT_TEMPLATE = 'GET_APARTMENT_TEMPLATE';
export const GET_APARTMENT_OPTION = 'GET_APARTMENT_OPTION';
export const GET_APARTMENT_ID = 'GET_APARTMENT_ID';
export const GET_APARTMENT_FACILITY = 'GET_APARTMENT_FACILITY';
export const PUT_APARTMENT = 'PUT_APARTMENT';
export const DELETE_APARTMENT = 'DELETE_APARTMENT';

export const REQUEST = {
  GET_APARTMENT: `REQUEST_${GET_APARTMENT}`,
  GET_APARTMENT_OPTION: `REQUEST_${GET_APARTMENT_OPTION}`,
  GET_APARTMENT_FACILITY: `REQUEST_${GET_APARTMENT_FACILITY}`,
  PUT_APARTMENT: `REQUEST_${PUT_APARTMENT}`,
  POST_APARTMENT: `REQUEST_${POST_APARTMENT}`,
  GET_APARTMENT_TEMPLATE: `REQUEST_${GET_APARTMENT_TEMPLATE}`,
  GET_APARTMENT_ID: `REQUEST_${GET_APARTMENT_ID}`,
  DELETE_APARTMENT: `REQUEST_${DELETE_APARTMENT}`
};

export const RECEIVE = {
  GET_APARTMENT: `RECEIVE_${GET_APARTMENT}`,
  GET_APARTMENT_OPTION: `RECEIVE_${GET_APARTMENT_OPTION}`,
  GET_APARTMENT_FACILITY: `RECEIVE_${GET_APARTMENT_FACILITY}`,
  PUT_APARTMENT: `RECEIVE_${PUT_APARTMENT}`,
  POST_APARTMENT: `RECEIVE_${POST_APARTMENT}`,
  GET_APARTMENT_ID: `RECEIVE_${GET_APARTMENT_ID}`,
  GET_APARTMENT_TEMPLATE: `RECEIVE_${GET_APARTMENT_TEMPLATE}`,
  DELETE_APARTMENT: `RECEIVE_${DELETE_APARTMENT}`
};

export const FAILURE = {
  GET_APARTMENT: `FAILURE_${GET_APARTMENT}`,
  GET_APARTMENT_OPTION: `FAILURE_${GET_APARTMENT_OPTION}`,
  GET_APARTMENT_FACILITY: `FAILURE_${GET_APARTMENT_FACILITY}`,
  PUT_APARTMENT: `FAILURE_${PUT_APARTMENT}`,
  POST_APARTMENT: `FAILURE_${POST_APARTMENT}`,
  GET_APARTMENT_ID: `FAILURE_${GET_APARTMENT_ID}`,
  GET_APARTMENT_TEMPLATE: `FAILURE_${GET_APARTMENT_TEMPLATE}`,
  DELETE_APARTMENT: `FAILURE_${DELETE_APARTMENT}`
};
