import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT,
  AUTH_LOGIN_FAILURE,
} from '../constant-type/constant-type-auth';

const InitialState = {
  loading: false,
  loggedIn: false,
  payload: {},
};

const auth = (state = InitialState, { type, payload }) => {
  switch (type) {
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        loadingLogin: false,
        payload: payload,
      };

    case AUTH_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case AUTH_LOGOUT:
      return { ...state, loggedIn: false };

    default:
      return state;
  }
};

export default auth;
