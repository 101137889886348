import axios from "axios";
// import { getEnv } from '../helpers/common-functions';
import { REQUEST } from "../redux/models/constant-type-req-rec";
import { ENV } from "./config-url";
import { logout } from "../redux/actions/action-auth";

export default function(dispatch) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL || ENV().api;
  axios.interceptors.request.use(config => {
    dispatch({
      type: REQUEST.START,
      payload: {
        method: config.method,
        url: config.url
      }
    });
    var token = localStorage.getItem("TOKEN_ADMIN");
    config.headers.common["Authorization"] = `Bearer ${token}`;
    config.headers.post["Content-Type"] = "application/json";
    return config;
  });

  axios.interceptors.response.use(
    config => {
      if (config.data.code === 405) {
        dispatch(logout());
      }
      return config;
    },
    err => {
      if (err.response) {
        if (err.response.data.code === 401) {
          dispatch(logout());
        } else {
          return Promise.reject(err);
        }
      }
      return Promise.reject(err);
    }
  );
}
