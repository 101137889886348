import { RECEIVE_STATISTIC_COUNT } from '../../actions/action-statistic/action-type';

const initialState = {
  code: null,
  message: '',
  payload: {
    residences: 0,
    residenceNew: 0,
    rt: 0,
    rw: 0,
    tower: 0,
    floors: 0,
    units: 0,
    apartments: 0,
    orderAllLaundry: 0,
    orderAllCarwash: 0,
    orderAllCleaning: 0,
    orderAllFurniture: 0,
    providerLaundry: 0,
    providerCarwash: 0,
    providerCleaning: 0,
    providerFurniture: 0,
  },
};
const statistic = (state = initialState, { type, payload }) => {
  switch (type) {
    case RECEIVE_STATISTIC_COUNT:
      return { ...state, ...payload };

    case 'CLEAR_STATISTIC':
      return { code: null, payload: {}, message: '' };

    default:
      return state;
  }
};

export default statistic;
