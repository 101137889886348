import {
  RECEIVE,
  REQUEST,
  FAILURE
} from '../../constant-type/constant-type-apartment';

const InitialState = {
  code: null,
  loading: false,
  payload: [],
  error: {},
  perpage: 0,
  count: 0
};

const apartment = (
  state = InitialState,
  { type, payload, code, perpage, count }
) => {
  switch (type) {
    case REQUEST.GET_APARTMENT_OPTION:
      return { ...state, loading: true };

    case RECEIVE.GET_APARTMENT_OPTION:
      return { ...state, loading: false, payload, code, perpage, count };

    case FAILURE.GET_APARTMENT_OPTION:
      return {
        ...state,
        loading: false,
        error: payload
      };

    default:
      return state;
  }
};

export default apartment;
