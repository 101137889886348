import {
  RECEIVE,
  REQUEST,
  FAILURE
} from '../../constant-type/constant-type-advertisement';

const InitialState = {
  code: null,
  loading: false,
  payload: {},
  message: ''
};

const apartment = (state = InitialState, { type, payload, code, message }) => {
  switch (type) {
    case REQUEST.POST_ADVERTISEMENT:
      return { ...state, loading: true };

    case RECEIVE.POST_ADVERTISEMENT:
      return { ...state, loading: false, payload, code };

    case FAILURE.POST_ADVERTISEMENT:
      return {
        ...state,
        loading: false,
        message,
        code
      };

    case 'DEFAULT_POST_ADVERTISEMENT':
      return {
        ...state,
        loading: false,
        message: '',
        code: null
      };

    default:
      return state;
  }
};

export default apartment;
