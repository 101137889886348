import {RECEIVE, REQUEST} from '../models/constant-type-req-rec';

const InitialState = {
  code:null,
  loading: false,
  payload: [],
  count:0,
  perpage:0
};

const services = (state = InitialState, { type, payload, code, count, perpage }) => {
  switch (type) {
    case REQUEST.SERVICE:
      return Object.assign({}, state, {loading:true});

    case RECEIVE.SERVICE:
      return Object.assign({}, state, { loading: false, payload,count, code, perpage});

    case REQUEST.SERVICE_ID:
      return Object.assign({}, state, {loading:true});

    case RECEIVE.SERVICE_ID:
      return Object.assign({}, state, { loading: false, payload,count, code, perpage});

    default:
      return state;
  }
};

export default services;