export const GET = {
  APARTMENTS:'GET_APARTMENTS',
  APARTMENTS_ID:'GET_APARTMENTS_ID',
  CARWASH_SERVICE: "GET_CARWASH_SERVICE",
  CAR_BRANDS:"GET_CAR_BRANDS",
  PROFILE:"GET_PROFILE",
  PROVIDER:"GET_PROVIDER",
  PROVIDER_ID:"GET_PROVIDER_ID",
  TOWER:"GET_TOWER",
  USER_MANAGEMENT:"GET_USER_MANAGEMENT",
  USER_MANAGEMENT_ID:"GET_USER_MANAGEMENT_ID",
  ORDERS:"GET_ORDERS",
  ORDERS_ID:"GET_ORDERS_ID",
  ORDERS_STATISTIC: "GET_ORDERS_STATISTIC",
};

export const PUT = {
  APARTMENTS:"PUT_APARTMENTS",
  CAR_WASH: "PUT_CAR_WASH",
  CAR_BRANDS:"PUT_CAR_BRANDS",
  PROVIDER:"PUT_PROVIDER",
  PROFILE:"PUT_PROFILE",
  USER_MANAGEMENT:"PUT_USER_MANAGEMENT",
  USER_PASSWORD:"PUT_USER_PASSWORD",
}

export const POST = {
  CAR_WASH:`POST_CAR_WASH`,
  CAR_BRANDS:"POST_CAR_BRANDS",
  APARTMENTS:"POST_APARTMENTS",
  PROVIDER:'POST_PROVIDER',
  USER_MANAGEMENT:'POST_USER_MANAGEMENT',
}

export const DELETE = {
  CAR_WASH:'DELETE_CAR_WASH',
  CAR_BRANDS:"DELETE_CAR_BRANDS",
  APARTMENTS:'DELETE_APARTMENTS',
  PROVIDER:'DELETE_PROVIDER',
  USER_MANAGEMENT:'DELETE_USER_MANAGEMENT',
}