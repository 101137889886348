import { GET, PUT, POST, DELETE } from './constant-type';

export const RECEIVE = {
  APARTMENTS: `RECEIVE_${GET.APARTMENTS}`,
  APARTMENTS_ID: `RECEIVE_${GET.APARTMENTS_ID}`,
  APARTMENTS_DELETE: `RECEIVE_${DELETE.APARTMENTS}`,
  APARTMENTS_PUT: `RECEIVE_${PUT.APARTMENTS}`,

  PROFILE: `RECEIVE_${GET.PROFILE}`,
  PROFILE_PUT: `RECEIVE_${PUT.PROFILE}`,

  PROVIDER: `RECEIVE_${GET.PROVIDER}`,
  PROVIDER_ID: `RECEIVE_${GET.PROVIDER_ID}`,
  PROVIDER_POST: `RECEIVE_${POST.PROVIDER}`,
  PROVIDER_DELETE: `RECEIVE_${DELETE.PROVIDER}`,
  PROVIDER_PUT: `RECEIVE_${PUT.PROVIDER}`,

  TOWER: `RECEIVE_${GET.TOWER}`,

  USER: `RECEIVE_USER`,
  USER_MANAGEMENT: `RECEIVE_GET_USER_MANAGEMENT`,
  USER_MANAGEMENT_POST: `RECEIVE_${POST.USER_MANAGEMENT}`,
  USER_MANAGEMENT_ID: `RECEIVE_${GET.USER_MANAGEMENT_ID}`,
  USER_MANAGEMENT_PUT: `RECEIVE_${PUT.USER_MANAGEMENT}`,
  USER_MANAGEMENT_DELETE: `RECEIVE_${DELETE.USER_MANAGEMENT}`,
  USER_PASSWORD_PUT: `RECEIVE_${PUT.USER_PASSWORD}`,

  CAR_WASH: `RECEIVE_GET_CAR_WASH`,
  CAR_WASH_ID: `RECEIVE_GET_CAR_WASH_ID`,
  CAR_WASH_SERVICE: `RECEIVE_GET_CAR_WASH_SERVICE`,
  CAR_WASH_ORDER: `RECEIVE_GET_CAR_WASH_ORDER`,
  CAR_WASH_DELETE: `RECEIVE_${DELETE.CAR_WASH}`,
  CAR_WASH_POST: `RECEIVE_${POST.CAR_WASH}`,
  CAR_WASH_PUT: `RECEIVE_${PUT.CAR_WASH}`,

  CAR_BRANDS_GET: `RECEIVE_${GET.CAR_BRANDS}`,
  CAR_BRANDS_POST: `RECEIVE_${POST.CAR_BRANDS}`,
  CAR_BRANDS_PUT: `RECEIVE_${PUT.CAR_BRANDS}`,
  CAR_BRANDS_DELETE: `RECEIVE_${DELETE.CAR_BRANDS}`,

  ORDERS: `RECEIVE_${GET.ORDERS}`,
  ORDERS_ID: `RECEIVE_${GET.ORDERS_ID}`,
  ORDERS_STATISTIC: `RECEIVE_${GET.ORDERS_STATISTIC}`
};

export const REQUEST = {
  APARTMENTS: `REQUEST_${GET.APARTMENTS}`,
  APARTMENTS_ID: `REQUEST_${GET.APARTMENTS_ID}`,
  APARTMENTS_DELETE: `REQUEST_${DELETE.APARTMENTS}`,
  APARTMENTS_PUT: `REQUEST_${PUT.APARTMENTS}`,

  PROFILE: `REQUEST_GET_PROFILE`,
  PROFILE_PUT: `REQUEST_PUT_PROFILE`,

  PROVIDER: `REQUEST_${GET.PROVIDER}`,
  PROVIDER_ID: `REQUEST_${GET.PROVIDER_ID}`,
  PROVIDER_POST: `REQUEST_${POST.PROVIDER}`,
  PROVIDER_DELETE: `REQUEST_${DELETE.PROVIDER}`,
  PROVIDER_PUT: `REQUEST_${PUT.PROVIDER}`,

  TOWER: `REQUEST_${GET.TOWER}`,

  START: `REQUEST_START`,
  END: `REQUEST_END`,

  USER: `REQUEST_USER`,
  USER_MANAGEMENT: `REQUEST_GET_USER_MANAGEMENT`,
  USER_MANAGEMENT_POST: `REQUEST_POST_USER_MANAGEMENT`,
  USER_MANAGEMENT_ID: `REQUEST_${GET.USER_MANAGEMENT_ID}`,
  USER_MANAGEMENT_PUT: `REQUEST_${PUT.USER_MANAGEMENT}`,
  USER_MANAGEMENT_DELETE: `REQUEST_${DELETE.USER_MANAGEMENT}`,
  USER_PASSWORD_PUT: `REQUEST_${PUT.USER_PASSWORD}`,

  CAR_WASH: 'REQUEST_GET_CAR_WASH',
  CAR_WASH_ID: 'REQUEST_GET_CAR_WASH_ID',
  CAR_WASH_SERVICE: 'REQUEST_GET_CAR_WASH_SERVICE',
  CAR_WASH_ORDER: 'REQUEST_GET_CAR_WASH_ORDER',
  CAR_WASH_DELETE: `REQUEST_${DELETE.CAR_WASH}`,
  CAR_WASH_POST: `REQUEST_${POST.CAR_WASH}`,
  CAR_WASH_PUT: `REQUEST_${PUT.CAR_WASH}`,

  CAR_BRANDS_GET: `REQUEST_${GET.CAR_BRANDS}`,
  CAR_BRANDS_POST: `REQUEST_${POST.CAR_BRANDS}`,
  CAR_BRANDS_PUT: `REQUEST_${PUT.CAR_BRANDS}`,
  CAR_BRANDS_DELETE: `REQUEST_${DELETE.CAR_BRANDS}`,

  ORDERS: `REQUEST_${GET.ORDERS}`,
  ORDERS_ID: `REQUEST_${GET.ORDERS_ID}`,
  ORDERS_STATISTIC: `REQUEST_${GET.ORDERS_STATISTIC}`
};

export const ERROR = {
  USER_MANAGEMENT_POST: 'ERROR_POST_USER_MANAGEMENT'
};

export const SET = {
  USER_MANAGEMENT_ERROR: 'ERROR_USER_MANAGEMENT',
  CAR_WASH_DELETE: `SET_${DELETE.CAR_WASH}`
};

export const CLEAR = {
  ERROR: 'CLEAR_ERROR'
};
