import {
  RECEIVE_MENU_V2,
  RECEIVE_MENU_ID,
  ONCHANGE_MENU,
  ONCHANGE_SUB_MENU,
  CLEAR_REDUCER_MENU,
  REQUEST_MENU_V2,
} from '../../actions/action-menu/action-menu-type.js';

const initialState = {
  list: {
    loading: false,
    statusCode: null,
    message: '',
    payload: [],
  },
  group: {
    role: 'management',
    menus: [],
  },
};

const reducerMenus = (
  state = initialState,
  { type, payload, menuID, subMenuID, isChecked }
) => {
  var menus;
  var findMenu;
  switch (type) {
    case REQUEST_MENU_V2:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };

    case RECEIVE_MENU_V2:
      menus = payload.payload.map(value => {
        findMenu =
          state.group.menus.length > 0 &&
          state.group.menus.find(function(element) {
            return element.id === value.id;
          });

        // console.log(findMenu.id === value.id);

        value.isChecked = state.group.menus.some(element => element.id === value.id);
        if (value.children) {
          value.children.map(sub => {
            sub.isChecked = findMenu
              ? findMenu.children.some(item => item.id === sub.id)
              : false;
            return sub;
          });
        }
        return value;
      });

      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          payload: menus,
        },
      };

    case RECEIVE_MENU_ID:
      menus = state.list.payload.map(value => {
        findMenu = payload.payload.find(function(element) {
          return element.id === value.id;
        });

        if (payload.payload.some(item => item.id === value.id)) {
          value.isChecked = true;
          if (value.children) {
            value.children.map(sub => {
              sub.isChecked =
                findMenu.children &&
                findMenu.children.some(item => item.id === sub.id);
              return sub;
            });
          }
        }
        return value;
      });

      let groupMenu = payload.payload.map(value => {
        if (value.children) {
          value.children.map(sub => {
            sub.isChecked = true;
            return sub;
          });
        }
        return value;
      });

      return {
        ...state,
        list: {
          loading: false,
          ...state.list,
          ...payload,
          payload: menus,
        },
        group: {
          ...state.group,
          menus: groupMenu,
        },
      };

    case ONCHANGE_MENU:
      menus = [...state.list.payload].map(value => {
        if (value.id === menuID) {
          if (!isChecked) {
            value.isChecked = false;
            value.children &&
              value.children.map(sub => {
                sub.isChecked = false;
                return sub;
              });
          } else {
            value.isChecked = true;
            value.children &&
              value.children.map(sub => {
                sub.isChecked = true;
                return sub;
              });
          }
        }
        return value;
      });

      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          payload: menus,
        },
        group: {
          ...state.group,
          menus: [...state.list.payload].filter(data => data.isChecked === true),
        },
      };

    case ONCHANGE_SUB_MENU:
      menus = [...state.list.payload].map(value => {
        if (value.id === menuID) {
          value.isChecked = true;
          value.children.map(sub => {
            if (sub.id === subMenuID) {
              sub.isChecked = isChecked;
            }
            return sub;
          });
          value.isChecked =
            value.isChecked &&
            value.children.filter(data => data.isChecked === true).length > 0;
        }
        return value;
      });

      return {
        ...state,
        list: {
          ...state.list,
          ...payload,
          payload: menus,
        },
        group: {
          ...state.group,
          menus: menus.filter(data => data.isChecked === true),
        },
      };

    case CLEAR_REDUCER_MENU:
      return {
        list: {
          loading: false,
          statusCode: null,
          message: '',
          payload: [],
        },
        group: {
          role: 'management',
          menus: [],
        },
      };

    default:
      return state;
  }
};

export default reducerMenus;
