export const GET_ICON = 'GET_ICON';
export const GET_ICON_ID = 'GET_ICON_ID';
export const PUT_ICON = 'PUT_ICON';
export const POST_ICON = 'POST_ICON';
export const DELETE_ICON = 'DELETE_ICON';

export const REQUEST = {
  GET_ICON: `REQUEST_${GET_ICON}`,
  PUT_ICON: `REQUEST_${PUT_ICON}`,
  POST_ICON: `REQUEST_${POST_ICON}`,
  GET_ICON_ID: `REQUEST_${GET_ICON_ID}`,
  DELETE_ICON: `REQUEST_${DELETE_ICON}`
};

export const RECEIVE = {
  GET_ICON: `RECEIVE_${GET_ICON}`,
  PUT_ICON: `RECEIVE_${PUT_ICON}`,
  POST_ICON: `RECEIVE_${POST_ICON}`,
  GET_ICON_ID: `RECEIVE_${GET_ICON_ID}`,
  DELETE_ICON: `RECEIVE_${DELETE_ICON}`
};

export const FAILURE = {
  GET_ICON: `FAILURE_${GET_ICON}`,
  PUT_ICON: `FAILURE_${PUT_ICON}`,
  POST_ICON: `FAILURE_${POST_ICON}`,
  GET_ICON_ID: `FAILURE_${GET_ICON_ID}`,
  DELETE_ICON: `FAILURE_${DELETE_ICON}`
};

export const DEFAULT = {
  GET_ICON: `DEFAULT_${GET_ICON}`,
  PUT_ICON: `DEFAULT_${PUT_ICON}`,
  POST_ICON: `DEFAULT_${POST_ICON}`,
  GET_ICON_ID: `DEFAULT_${GET_ICON_ID}`,
  DELETE_ICON: `DEFAULT_${DELETE_ICON}`
};
