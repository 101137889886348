import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import loggerMiddlware from 'redux-logger';
// export default [promise(), logger, thunk];
export default [
  promise(),
  thunk,
  loggerMiddlware,
  loadingBarMiddleware({ scope: 'sectionBar' }),
];
