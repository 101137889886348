import {
  RECEIVE,
  REQUEST,
  FAILURE
} from '../../constant-type/constant-type-apartment';

const InitialState = {
  code: null,
  loading: false,
  payload: {},
  error: {}
};

const apartment = (
  state = InitialState,
  { type, payload, code, perpage, count }
) => {
  switch (type) {
    case REQUEST.POST_APARTMENT:
      return { ...state, loading: true };

    case RECEIVE.POST_APARTMENT:
      return { ...state, loading: false, payload, code, perpage, count };

    case FAILURE.POST_APARTMENT:
      return {
        ...state,
        loading: false,
        error: payload
      };

    case 'DEFAULT_POST_APARTMENT':
      return {
        ...state,
        loading: false,
        error: {},
        code: null
      };

    default:
      return state;
  }
};

export default apartment;
