import auth from './reducer-auth';
import menu from './reducer-menu';
import user from './reducer-user';
import profile from './reducer-profile';
import services from './reducer-services';
// import apartments from './reducer-apartments';
import { dendaDelete, dendaGet, dendaGetId, dendaPut } from './denda';
import {
  apartmentGet,
  apartmentFacilityGet,
  apartmentGetOption,
  apartmentPost,
  apartmentGetId,
  apartmentPut,
} from './apartment';

import { voucherGet, voucherUsageGet } from './voucher';
import { iconGet, iconPost } from './icon';
import ordersStatistic from './reducer-orders-statistic';
import notification from './reducer-notifications';
import { advertisementGet, advertisementPost } from './advertisement';
import { routerReducer as routing } from 'react-router-redux';
import provider from './reducer-provider';

import { loadingBarReducer } from 'react-redux-loading-bar';
import reducerMenus from './reducer-menus';
import reducerApartments from './reducer-apartments/index';
import notificationSystem from './reducer-notification-system';
import reducerStatistic from './reducer-statistic';
import reducer_emergencies from './reducer-emergencies/reducer-emergencies';

export default {
  dendaDelete,
  dendaGet,
  dendaGetId,
  dendaPut,
  auth,
  menu,
  profile,
  routing,
  services,
  user,
  // apartments,
  apartmentGet,
  apartmentFacilityGet,
  apartmentGetId,
  apartmentPost,
  apartmentPut,
  apartmentGetOption,

  advertisementGet,
  advertisementPost,

  iconGet,
  iconPost,

  voucherGet,
  voucherUsageGet,

  ordersStatistic,
  notification,
  provider,
  loadingBar: loadingBarReducer,
  menus: reducerMenus,
  apartments: reducerApartments,
  notificationSystem,
  statistic: reducerStatistic,
  reducer_emergencies,
};
