import { RECEIVE_APARTMENTS } from '../../actions/action/action-apartments/action-type';

const initialState = {
  loading: false,
  data: {
    code: null,
    perpage: 0,
    count: 0,
    payload: [],
  },
};

const reducer_apartments = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_APARTMENTS:
      return { ...state, data: action.payload };

    default:
      return state;
  }
};

export default reducer_apartments;
